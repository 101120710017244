<template>
  <div>
      <h1 class='unauth'><v-icon x-large>mdi-security</v-icon>Unauthorized</h1>
      <h2 class="unauth">You are not authorized to view the page you were attempting to access.</h2>
      <h2 class="unauth">Continued attempts will result in your account being locked.</h2>
  </div>
</template>

<script>
export default {
  name: 'Unauthorized'
}
</script>

<style scoped>
.unauth {
  color: red;
}
</style>
